import './App.css';
import { useEffect, useState } from 'react';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { SaibaMais } from './components/SaibaMais';
import { cadastrarDados, fazGet } from './api/Api'
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-J11H4SY68E');
function App() {
  const [politica, setPolitica] = useState(false);
  const [saibaMais, setSaibaMais] = useState(false);
  const [vhsys, setVhsys] = useState(false);

  useEffect(() => {
    ReactGA.send("pageview");
  })

  var url_string = window.location.href //http://localhost:3000/t.html?origem=youtube
  var url = new URL(url_string);
  var origem = url.searchParams.get("origem");

  const verPolitica = () => {
    setPolitica(!politica)
  }

  const verSaibaMais = () => {
    setSaibaMais(!saibaMais)
  }

  const clickDownload = () => {

    let url = "https://api2.apploja.com/lib/safetymails/validar-email/?email=contato@apploja.com";

    console.log(url);

    fazGet(url).then((data) => {
    });

    let checkbox = document.getElementById('privacy-policy')

    let name = document.getElementById('name').value
    let email = document.getElementById('email').value
    let phone = document.getElementById('phone').value
    let empresa = document.getElementById('empresa').value
    let funcionarios = document.getElementById('funcionarios').value
    let faturamento = document.getElementById('faturamento').value
    let interesse = document.getElementById('interesse').value

    if ((email != "") && (name != "") && (phone != "") &&
      (empresa != "") && (funcionarios != "") && (faturamento != "") && (interesse != "")) {
      if (checkbox.checked) {

        if (origem == "camilamendes") {
          window.location.href = 'https://www.apploja.net/download-apploja-free'
        } else {
          window.location.href = 'https://www.apploja.net/download-apploja-light'
        }
        cadastrarDados(origem)
      } else {
        alert("Você precisa concordar com as políticas de privacidade e tratamento de dados");
      }
    } else {
      alert("Todos os campos são obrigatórios")
    }

  }

  const handleSection = () => {
    if (document.getElementById('funcionarios').value === "1" ||
      document.getElementById('funcionarios').value === "2" ||
      document.getElementById('funcionarios').value === "3" ||
      document.getElementById('funcionarios').value === "4" ||
      document.getElementById('funcionarios').value === "5" ||
      document.getElementById('funcionarios').value === "6" ||
      document.getElementById('funcionarios').value === "7" ||
      document.getElementById('funcionarios').value === "8" ||
      document.getElementById('funcionarios').value === "9" ||
      document.getElementById('funcionarios').value === "10" ||
      document.getElementById('funcionarios').value === "Mais de 10") {
      if (document.getElementById('faturamento').value === "De 20 à 30 mil" ||
        document.getElementById('faturamento').value === "De 30 à 40 mil" ||
        document.getElementById('faturamento').value === "De 40 à 50 mil" ||
        document.getElementById('faturamento').value === "Mais de 50 mil") {
          if (document.getElementById('empresa').value === "Sim") {
            setVhsys(true)
          } else {
            setVhsys(false)
          }
      } else {
        setVhsys(false)
      }
    } else {
      setVhsys(false)
    }
  }

  document.title = 'Download APPLoja'
  return (
    <div className="App">
      <body>
        {politica ?
          <PrivacyPolicy verPolitica={verPolitica} />
          : ""}
        {saibaMais ?
          <SaibaMais verSaibaMais={verSaibaMais} />
          : ""}
        <div className='container'>
          <div className='side-left'>
            <h1>O SISTEMA IDEAL PARA QUEM BUSCA UM CONTROLE DE VENDAS PARA SEU NEGÓCIO</h1>
            <div className='video-apresentacao'>
              <div className='video-youtube'>
                <iframe
                  width="100%"
                  height="100%" src="https://www.youtube.com/embed/Im9lIQ3G_BY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
                </iframe>
              </div>
            </div>
            <h3>Todo empreendedor precisa ter controle sobre seu negócio, e clareza das informações fornecidas pelo seu aplicativo de gestão.</h3>
            <h3>O APPloja é o sistema de gestão que vai auxiliar na automação do registro de vendas, controle do seu estoque e ter a visão do que está ocorrendo na sua empresa, tranquilizando seu trabalho e otimizando seu tempo.</h3>
            <ul>
              <h3>Para quem é o APPLoja</h3>
              <li>Não sabe ao certo quanto está faturando;</li>
              <li>Não sabe se a empresa está dando resultado positivo;</li>
              <li>Não sabe o estoque do produto e quando precisa comprar mais;</li>
              <li>Perde tempo emitindo notas fiscais;</li>
            </ul>
          </div>
          <div className='side-right'>
            <div className='image-logo'>
              <img src='https://aquino.sfo2.digitaloceanspaces.com/clieent.com/prod/AC855/clieent-2022020325e669.png' />
            </div>
            <h2>Receba gratuitamente seu controle de estoque e vendas!</h2>
            <p>Conheça o sistema que já ajudou milhares de empresários à ir para o próximo nível de gestão</p>
            <form className='input-form'>
              <label>Nome Completo</label>
              <input id="name" type="text" className="input-right" placeholder="Nome Completo" />
              <label>E-mail</label>
              <input id="email" type="email" className="input-right" placeholder="E-mail" />
              <label>Whatsapp com DDD</label>
              <InputMask id="phone" className="input-right" mask="(99) 9 9999-9999" placeholder="Whatsapp com DDD" />
              <label>Tem empresa</label>
              <select onChange={handleSection} id='empresa' className="input-right">
                <option value="">Tem empresa</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
              </select>
              <label>Quantidade de Funcionario</label>
              <select onChange={handleSection} id='funcionarios' className="input-right">
                <option value="">Quantidade de Funcionario</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="Mais de 10">Mais de 10</option>
              </select>
              <label>Média de Faturamento</label>
              <select onChange={handleSection} id='faturamento' className="input-right">
                <option value="">Média de Faturamento</option>
                <option value="De 0 à 5 mil">De 0 à 5 mil</option>
                <option value="De 5 à 10 mil">De 5 à 10 mil</option>
                <option value="De 10 à 20 mil">De 10 à 20 mil</option>
                <option value="De 20 à 30 mil">De 20 à 30 mil</option>
                <option value="De 30 à 40 mil">De 30 à 40 mil</option>
                <option value="De 40 à 50 mil">De 40 à 50 mil</option>
                <option value="Mais de 50 mil">Mais de 50 mil</option>
              </select>
              <div>
                <label>Tem interesse na versão paga?</label>
                <select onChange={handleSection} id='interesse' className="input-right">
                  <option value="">Tem interesse na versão paga?</option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
              </div>
              {vhsys ?
                <div className='div-vhsys'>
                  <input id='vhsys' type="checkbox" />
                  <scan>Tem interesse em testar, por 7 dias, nosso sistema completo?<a onClick={verSaibaMais}>Saiba Mais</a></scan>
                </div>
                : ""}
            </form>
            <div className='checkbox-div'>
              <input id='privacy-policy' type="checkbox" />
              Eu concordo com a coleta e tratamento dos meus dados pessoais para
              receber comunicações e informações sobre produtos e serviços e declaro
              ter lido e concordado com a nossa <a onClick={verPolitica}>política de privacidade e tratamento de dados.</a>
            </div>
            <button onClick={clickDownload}>DOWNLOAD</button>
          </div>
        </div>
      </body>
    </div>
  );
}

export default App;
