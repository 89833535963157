export const cadastrarDados = (origem) => {
    let nome = document.getElementById("name").value
    let email = document.getElementById("email").value
    let phone = document.getElementById("phone").value
    let empresa = document.getElementById("empresa").value
    let funcionarios = document.getElementById("funcionarios").value
    let faturamento = document.getElementById("faturamento").value
    let interesse = document.getElementById("interesse").value
    let vhsys = ""
    if (document.getElementById("vhsys")) {
        let checkboxVhsys = document.getElementById("vhsys");
        if (checkboxVhsys.checked) {
            vhsys = "Sim"
        } else {
            vhsys = "Não"
        }
    }

    const data =
    {
        nome: nome,
        email: email,
        celular: phone,
        empresa: empresa,
        funcionarios: funcionarios,
        faturamento: faturamento,
        interesse: interesse,
        vhsys: vhsys,
        origem: origem
    };

    console.log("Data", data)

    fazPost(data);
}

const fazPost = async (data) => {

    const requestOptions = {
        mode: 'no-cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };
    
    await fetch('https://usuarios2.apploja.com/v1/crm/receberlead/', requestOptions)
    .then(response => {
        // valida se a requisição falhou
        if (!response.ok) {
            console.log('fail', 'falhou a requisição', response)
          return new Error('falhou a requisição') // cairá no catch da promise
        }
  
        // verificando pelo status
        if (response.status === 404) {
            console.log('Erro404', 'não encontrou qualquer reusultado')
          return new Error('não encontrou qualquer resultado')
        }
        console.log('resposta', response.json())
        // retorna uma promise com os dados em JSON
        return response.json()
      })
}

export const fazGet = async (url) => {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Origin, Access-Control-Allow-Methods, Access-Control-Allow-Headers',
        },
    };
    
    const request = new Request(url, requestOptions)

    const data = await fetch(request)
    .then(res => res.json())
    .catch(err => console.error(err))
    console.log(data)
    return data;
}