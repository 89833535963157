import './styles.css'
import vhsysImg from '../../assets/image/vhsys.png'

export const SaibaMais = (props) => {
    return (
        <div className='saiba-mais'>
            <a className="close-saiba-mais" onClick={props.verSaibaMais}>Voltar</a>
            <span className="title-saiba-mais">
                Sistema Completo
            </span>
            <div className='image-saiba-mais'>
                <img src={vhsysImg} />
            </div>
            <p>Com o sistema completo, você centraliza e automatiza a gestão do seu negócio em um único sistema e ganha tempo para investir no que realmente importa: o crescimento da sua empresa.
            </p>
            <p>Sistema completo, feito para facilitar as rotinas operacionais de micro e pequenas empresas. Com ele, você centraliza as informações do negócio e pode acessar de qualquer lugar e hora, pelo computador, celular ou tablet.
            </p>
            <p>No programa completo, você consegue emitir todos os tipos de notas fiscais, assim como controlar o vendas, financeiro e estoque. Tudo de forma simples, prática e integrada.
            </p>
            <p>Além disso, não é preciso instalar o programa ou mesmo comprar licença de uso. Como o sistema é online, o acesso é feito via usuário e senha, de forma totalmente segura.
            </p>
            <div className='video-saiba-mais'>
                <iframe
                    src="https://www.youtube.com/embed/SGwCxglza9Y"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    )
}