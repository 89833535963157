import './styles.css'

export const PrivacyPolicy = (props) => {
    return (
        <div className='privacy-policy'>
            <a className="close-policy" onClick={props.verPolitica}>Voltar</a>
            <span className="title-policy">
              Política de Privacidade e Tratamento de Dados
            </span>
            <p>TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE
            </p><p>Pelo presente instrumento particular de licença de uso de SISTEMA DE GESTÃO, de um lado APP LOJA, CNPJ n. 27.726.640/0001-30, com sede na Avenida Luiz Armando Ophis, 294, Estação, Município de Araucária, Estado do Paraná, doravante denominada “CONTRATADA” e ainda, de outro lado, a pessoa, física ou jurídica, doravante denominada “USUÁRIO” ou “CONTRATANTE”, têm entre si justo e avençado o seguinte:
            </p><p>1. ACEITAÇÃO DO CONTRATO E DOCUMENTOS INTEGRANTES
            </p><p>1.1. Ao optar por um dos planos oferecidos, o USUÁRIO automaticamente concederá aceitação irrevogável ao conteúdo do presente instrumento, e concordará, ainda, que qualquer indivíduo que utilizar a sua conta em seu nome também respeitará as condições aqui estipuladas.
            </p><p>1.2. A aceitação deste manifesta a vontade inequívoca das Partes no contrato celebrado por meio eletrônico e na concordância das condições necessárias para utilização do serviço,
            </p><p>1.3. não aceitação ou violação implicará no encerramento de sua conta.
            </p><p>1.4. A CONTRATADA se resguarda no direito, de revisar estas condições a qualquer momento, sendo que o uso continuado do serviço por parte do USUÁRIO será considerado como aceitação tácita de tais revisões, estando desde já ciente que versão mais atual poderá ser consultada a qualquer momento no site.
            </p><p>2. DEFINIÇÕES
            </p><p>2.1. Fica estabelecido as seguintes definições:
            </p><p>a) USUÁRIO/USUÁRIOS: é a pessoa física ou jurídica que contrata o serviço.
            </p><p>b) SUPORTE TÉCNICO: é o serviço de suporte técnico disponibilizado ao USUÁRIO pelo telefone (41) 9 9531-3382 ou e-mail <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="b4d7dbdac0d5c0dbf4d5c4c4d8dbded59ad7dbd99a">[email&#160;protected]</a>
            </p><p>c) SISTEMA DE GESTÃO: é a disponibilização, pela CONTRATADA, de um conjunto de ferramentas e serviços online destinados aos USUÁRIOS. Os Serviços são fornecidos mediante acesso à internet, por meio de dispositivos próprios de propriedade do USUÁRIO. Para tanto, a CONTRATADA se exime de qualquer responsabilidade sobre os equipamentos e meios de acesso utilizados pelos USUÁRIOS, como, por exemplo, computador, provedores de acesso à Internet, modem, bem como, qualquer perda de dados.
            </p><p>d) SITE: é a página ou sequência de páginas do SISTEMA DE GESTÃO desenvolvido pela CONTRATADA, com a finalidade de ofertar o Serviço.
            </p><p>3. OBJETO
            </p><p>3.1. Pelo presente contrato compromete-se a CONTRATADA a fornecer à CONTRATANTE, a licença de uso do(s) sistema(s) abaixo descrito(s), conforme plano contratado, cujas especificidades estão descritas na página principal do SITE:
            </p><p>a) PRO:
            </p><p>Preço: R$ 79,90 (Pagamento único)
            </p><p>Licença Vitalícia
            </p><p>Sistema apenas para PC
            </p><p>Sistema para computador
            </p><p>Cadastro de Produtos
            </p><p>Cadastro Fornecedores
            </p><p>Cadastro Clientes
            </p><p>Ponto de Venda PDV
            </p><p>Controle de Pedidos
            </p><p>Controle de Usuários
            </p><p>Controle de Vendedores
            </p><p>Controle de Compras
            </p><p>Emissão de Carnê
            </p><p>Nota Promissória
            </p><p>Controle de Consignado
            </p><p>Emissão de Boleto
            </p><p>Controle de Despesas
            </p><p>Controle de Receitas
            </p><p>Controle de Caixa
            </p><p>Extrato Financeiro
            </p><p>Controle de Entregas
            </p><p>Ordem de Serviço
            </p><p>Suporte para Instalação
            </p><p>b) Premium:
            </p><p>Preço: R$ 142,50 (Pagamento anual)
            </p><p>Sistema para PC integrado a aplicativo android para vendas externas em até 3 celulares. Sendo cobrado R$ 50 por celular adicional
            </p><p>No PC tem os seguintes recursos
            </p><p>Cadastro de Produtos
            </p><p>Cadastro Fornecedores
            </p><p>Cadastro Clientes
            </p><p>Venda Balcão PDV
            </p><p>Controle de Pedidos
            </p><p>Controle de acesso por usuário
            </p><p>Compra de Materiais
            </p><p>Emissão de Carnê
            </p><p>Nota Promissória
            </p><p>Emissão de Boleto
            </p><p>Controle de Despesas
            </p><p>Controle de Receitas
            </p><p>Controle de Caixa
            </p><p>Margem de lucro
            </p><p>Controle de Entregas
            </p><p>Ordem de Serviço
            </p><p>Suporte para Instalação
            </p><p>Recursos do aplicativo android
            </p><p>Vendas
            </p><p>Cadastro e consulta de produtos
            </p><p>Cadastro e consulta de clientes e fornecedores
            </p><p>Abertura e fechamento do caixa
            </p><p>Sincronização das vendas, efetuadas no celular, para o PC
            </p><p>c) Completo:
            </p><p>Preço: À partir de 89,90 mensal
            </p><p>Sistema web com emissão de nota fiscal
            </p><p>Acesse pelo no navegador em qualquer lugar
            </p><p>Agenda
            </p><p>Aplicativo Android e IOS
            </p><p>Venda pelo celular
            </p><p>Emissor de Notas
            </p><p>NF-e / NFS-e / NFC-e / CT-e / MDF-e
            </p><p>Controle de Vendas
            </p><p>PDV
            </p><p>Pedidos
            </p><p>Ordem de Serviço
            </p><p>Loja Virtual Integrada
            </p><p>Controle Financeiro
            </p><p>Contas à Pagar
            </p><p>Contas à Receber
            </p><p>Boleto
            </p><p>Integração com Ecommerce
            </p><p>Marketplace
            </p><p>Antecipação de recebíveis em Boleto
            </p><p>Controle de Estoque
            </p><p>Relatórios Detalhados
            </p><p>Avisos Internos
            </p><p>Recursos Adicionais
            </p><p>d) Delivery:
            </p><p>Sistema Desktop
            </p><p>Mobile (Android)
            </p><p>Delivery
            </p><p>3.2. O suporte técnico refere-se à correção de defeitos e dúvidas que não possam ser sanadas pelos tutoriais limitado, sendo limitado à 10 (dez) atendimentos ou 3 (três) meses da compra.
            </p><p>3.2.1. Após o término dos 10 (dez) atendimentos, ou do período de 3 (três) meses, será cobrado R$ 5 (cinco reais) por atendimento.
            </p><p>3.2.2. O suporte técnico será prestado de segunda-feira à sexta-feira, no horário de 9 (nove) às 18 (dezoito) horas.
            </p><p>3.2.3. O prazo de atendimento é de até 3 horas úteis, podendo ser maior em razão da complexidade da situação e da demanda.
            </p><p>3.3. Poderá ser contratado treinamento para o conhecimento das funcionalidades do sistema no valor de R$ 25 (vinte e cinco reais) por hora.
            </p><p>3.4. O serviço será prestado da seguinte forma:
            </p><p>a) acesso pago com diversas opções de pacotes de funcionalidades, cujas especificidades estão descritas na página principal do SITE;
            </p><p>b) após a confirmação do pagamento, será gerada a chave de ativação para download e enviadas instruções para utilização do sistema;
            </p><p>c) a versão gratuita do SISTEMA DE GESTÃO por ser utilização sem limitação de tempo;
            </p><p>d) é possível o cancelamento da contratação no prazo máximo de 7 (sete) dias da escolha do plano;
            </p><p>e) é possível o upgrade de forma imediata em relação ao plano contratado, mediante a contratação de um novo plano, com a utilização do crédito remanescente do plano anterior para extensão do período de uso no novo plano;
            </p><p>f) é possível o downgrade de forma imediata em relação ao plano escolhido. Porém, não haverá devolução do valor pago a maior.
            </p><p>3.5. A CONTRATADA se reserva o direito de mudar, modificar, suspender ou descontinuar qualquer plano, cujas especificidades estão descritas na página principal do SITE, bem como as opções de contas, valores e formatos a serem ofertados ao USUÁRIO.
            </p><p>3.6. O presente contrato terá a seguinte duração:
            </p><p>a) PRO: Licença Vitalícia;
            </p><p>b) Premium: 12 (doze) meses contados a partir da formalização da opção pelo plano;
            </p><p>c) Completo: 01 (um) mês;
            </p><p>d) Delivery: 01 (um) mês;
            </p><p>3.7. Após o período de duração mencionado no item anterior, o SISTEMA DE GESTÃO apresentará um alerta de expiração com prazo de 7 (sete) dias para a CONTRATANTE renovar sua opção pelo plano escolhido ou optar por outro plano. Caso a opção não seja feita, o uso do SISTEMA DE GESTÃO será bloqueado.
            </p><p>3.8. O USUÁRIO é o único responsável pelo cancelamento do serviço, o qual só poderá ser feito respeitando as condições previstas no presente contrato.
            </p><p>3.9. Após o cancelamento do serviço, todas as informações, dados e arquivos ficarão disponíveis para backup pelo prazo de 90 (noventa) dias, após o qual, serão automaticamente apagados.
            </p><p>3.10. Caso o cancelamento seja feito em desrespeito às condições estipuladas neste instrumento, o encerramento da conta terá efeito imediato e o USUÁRIO não terá direito ao reembolso do período contratado.
            </p><p>3.11. A CONTRATADA se resguarda no direito de rescindir imediatamente o contrato de um determinado USUÁRIO caso haja indícios de que o SISTEMA DE GESTÃO esteja utilizando de forma ilícita ou vinculado a atos ilícitos.
            </p><p>4. PREÇO
            </p><p>4.1. O USUÁRIO deverá pagar à CONTRATADA o valor do plano contratado, cujas especificidades e valores estão descritas na página principal do SITE no ato da contratação.
            </p><p>4.2. A data de vencimento contar-se-á a partir da data de contratação, conforme as especificidades do plano contratado, descritas na página principal do SITE no ato da contratação.
            </p><p>4.3. Na hipótese da duração do contrato ser anual, os valores ora pactuados serão corrigidos, de forma anual, pelo IGPM ou outro indexador oficial que venha a substituí-lo. A correção dar-se-á periodicamente a cada 12 (doze) meses. Em caso de variação negativa do índice de correção, não haverá redução do valor anteriormente estabelecido.
            </p><p>4.4. A falta de pagamento dos valores acordados nas respectivas datas de vencimento não acarretará a rescisão automática do contrato, mas implicará na suspensão do acesso do USUÁRIO ao SISTEMA DE GESTÃO até que as pendências financeiras tenham sido regularizadas. O acesso ao SISTEMA DE GESTÃO somente será restabelecido após a identificação do pagamento integral de todos os valores devidos pelo USUÁRIO, mesmo durante o período em que o acesso esteve suspenso.
            </p><p>4.5. A compensação do pagamento poderá ocorrer em até 2 (dois dias úteis) após a data de pagamento.
            </p><p>4.6. Caso a pendência no pagamento dos valores contratados permaneça por prazo superior à 90 (noventa) dias contados da data de vencimento do valor não pago, a CONTRATADA se reserva no direito de rescindir o presente contrato. Nesse caso, o USUÁRIO declara estar ciente que todos os seus dados e informações inseridos no SISTEMA DE GESTÃO serão apagados de forma definitiva e irrecuperável.
            </p><p>5. OBRIGAÇÕES DA CONTRATADA
            </p><p>5.1. São obrigações da CONTRATADA, além das demais previstas neste instrumento e na legislação aplicável:
            </p><p>a) Disponibilizar o SISTEMA DE GESTÃO envidando os melhores esforços para assegurar e desenvolver a qualidade do mesmo, comprometendo-se, ainda, a respeitar a privacidade do USUÁRIO, garantindo que não monitorará ou divulgará informações relativas a sua utilização, mantendo sigilo sobre as informações cadastrais fornecidas, inclusive login e senha, que só serão divulgados a terceiros em razão de determinação judicial, ressalvadas as hipóteses previstas neste Contrato, respeitando-se a legislação brasileira vigente sobre o tema;
            </p><p>b) Comunicar ao USUÁRIO por e-mail ou aviso veiculado no Portal e/ou no SITE a interrupção da prestação do serviço por ocasião de manutenções preventivas e/ou corretivas em sua rede;
            </p><p>c) Manter, por si ou por terceiros, o SUPORTE TÉCNICO;
            </p><p>d) Prover, nos termos deste contrato, os suportes técnicos que forem indispensáveis ao uso do SISTEMA DE GESTÃO.
            </p><p>6. OBRIGAÇÕES DO USUÁRIO
            </p><p>6.1. São obrigações do USUÁRIO, além das demais previstas neste instrumento e na legislação aplicável:
            </p><p>a) Pagar os valores devidos de acordo com o plano escolhido;
            </p><p>b) Responsabilizar-se pelo uso e guarda de seu login e senha de acesso ao SISTEMA DE GESTÃO;
            </p><p>c) Utilizar o SISTEMA DE GESTÃO colocados à sua disposição somente para os fins estabelecidos no presente instrumento, honrando os compromissos legais aqui assumidos;
            </p><p>d) Utilizar o SISTEMA DE GESTÃO para fins legais ou em consonância com a legislação brasileira.
            </p><p>6.2. O USUÁRIO será o único e exclusivo responsável pela utilização do SISTEMA DE GESTÃO, respondendo perante a CONTRATADA e/ou terceiros pelos danos e prejuízos, inclusive lucros cessantes que vier a ocasionar em razão da má utilização do mesmo.
            </p><p>6.3. O USUÁRIO será o único responsável pelas infrações que cometer quanto ao direito de uso dos softwares ou outro processo de execução protegido por marcas e patentes relacionados a este instrumento, respondendo diretamente por quaisquer indenizações, taxas ou comissões que forem devidas, bem como por quaisquer reclamações resultantes de sua utilização inadequada.
            </p><p>6.4. São de propriedade exclusiva da CONTRATADA o conteúdo do SITE, como textos, gráficos, planilhas, ícones, desenhos, programas de computador, aí incluídos o suporte lógico, a descrição básica e o material de apoio, os dados de qualquer natureza relacionados aos serviços objeto deste contrato, estando todos protegidos pelas leis brasileiras de direito intelectual, especialmente as de direito autoral, de proteção jurídica do programa de computador, de marcas, patentes, modelos e desenhos industriais, bem como pelos tratados internacionais relativos a matéria.
            </p><p>6.5. O uso não autorizado, a divulgação, ainda que restrita, especialmente a de caráter comercial, a publicação, distribuição, transmissão, compilação, alteração, assim como a reprodução total ou parcial não autorizada dos conteúdos disponibilizados pelo SITE, por meio mecânico, eletrônico ou qualquer outro, são práticas estritamente proibidas, sujeitando o infrator as sanções de natureza penal, civil e administrativa cabíveis.
            </p><p>7. INTERRUPÇÕES, GARANTIAS E RESPONSABILIDADES
            </p><p>7.1. O uso do SISTEMA DE GESTÃO poderá ser interrompido, sem que haja qualquer direito de indenização ou compensação ao USUÁRIO, eventualmente para:
            </p><p>a) manutenções técnicas e/ou operacionais que exijam o desligamento temporário do sistema ou impossibilitem o acesso ao mesmo;
            </p><p>b) casos fortuitos ou de força maior;
            </p><p>c) ações de terceiros que impeçam a prestação contínua do serviço;
            </p><p>d) falta de fornecimento de energia elétrica por parte da concessionária pública;
            </p><p>e) interrupção ou suspensão da prestação dos serviços de telecomunicações;
            </p><p>f) ocorrências de falhas no sistema de transmissão e/ou roteamento no acesso à Internet.
            </p><p>7.2. A CONTRATADA não será responsável por danos e/ou prejuízos decorrentes de interrupções relacionadas aos eventos previstos nos itens acima, ou de outros em que não tenha concorrido exclusivamente para sua ocorrência.
            </p><p>7.3. Em razão de situações de fragilidade ou eventualidades, que podem ocasionar a suspensão ou falha temporária de algumas funcionalidades, a CONTRATADA não garante o funcionamento do SISTEMA DE GESTÃO de forma ininterrupta e/ou isenta de erros, mas se compromete sempre a zelar pelo restabelecimento das condições ideais de uso.
            </p><p>7.4. A CONTRATADA isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, prejuízos de quaisquer espécies ou sob quaisquer títulos, perdas de negócios, perda ou extravio de dados, defeitos de computador, equipamentos ou periféricos, ou quaisquer outros danos diretos, indiretos, acidentais, especiais, consequenciais ou punitivos, decorrentes direta ou indireta da utilização do SISTEMA DE GESTÃO causados ao USUÁRIO ou a terceiros.
            </p><p>7.5. A CONTRATADA assegura ao CONTRATANTE a possibilidade de backup de seus dados mesmo com o uso do plano suspenso, assegurando ainda em casos de cancelamento do serviço, o prazo de 90 (noventa) dias para backup das informações e dados armazenados no SISTEMA DE GESTÃO.
            </p><p>8. DADOS CADASTRAIS
            </p><p>8.1. Ao efetuar seu cadastro aceitando as condições e termos deste contrato, o USUÁRIO declara e garante, expressamente, para todos os fins de direito, possuir capacidade jurídica para sua celebração, bem como que é financeiramente responsável pela utilização dos produtos e serviços objeto deste Contrato.
            </p><p>8.2. O USUÁRIO compromete-se a comunicar imediatamente a CONTRATADA sobre qualquer extravio, roubo, perda ou uso não autorizado de seu login e/ou senha, a fim de que possam ser bloqueados ou modificados.
            </p><p>8.3. A CONTRATADA exonera-se de toda e qualquer responsabilidade decorrente do uso indevido, negligente ou imprudente do login e/ou da senha por parte do USUÁRIO.
            </p><p>8.4. O USUÁRIO deverá fornecer, no momento da contratação, informações verdadeiras, atualizadas e completas, sendo facultado à CONTRATADA utilizar serviço próprio ou de terceiros para apurar a validade e veracidade das informações prestadas. O USUÁRIO deverá, ainda, informar qualquer alteração em seus dados cadastrais, inclusive mudança de endereços convencional ou e-mail.
            </p><p>8.5. Caso os dados informados pelo USUÁRIO no momento do cadastramento estejam errados ou incompletos, sofram alteração não comunicada nos termos do item anterior, ou impossibilitem a comprovação e identificação do USUÁRIO, a CONTRATADA poderá, a seus exclusivos critérios, suspender ou cancelar automaticamente e independente de comunicação prévia, os serviços ora contratados.
            </p><p>8.6. O registro e a utilização eletrônica dos dados cadastrais, tem como finalidade o estabelecimento do vínculo contratual, a gestão, administração, prestação, ampliação e aprimoramento dos serviços ao USUÁRIO, bem como a adequação dos serviços às preferências e gostos do USUÁRIO, a criação de novos serviços, o envio de atualizações dos serviços, o envio, por meios tradicionais e/ou eletrônicos, de informações técnicas, operacionais e comerciais relativas a produtos e serviços oferecidos direta ou indiretamente pela CONTRATADA.
            </p><p>8.7. A finalidade do registro e do tratamento eletrônico dos dados cadastrais inclui, igualmente, o envio de formulários de pesquisas, aos quais o USUÁRIO não fica obrigado a responder.
            </p><p>8.8. A CONTRATADA deverá obter o prévio e inequívoco consentimento do USUÁRIO para utilizar os dados cadastrais com propósitos diversos ao estabelecido nesta Cláusula.
            </p><p>8.9. A CONTRATADA garante que adota os melhores níveis quanto à segurança na proteção dos referidos dados cadastrais do USUÁRIO, tendo instalado os meios e medidas técnicas para tentar evitar a perda, mau uso, alteração, acesso não autorizado ou subtração indevida dos dados cadastrais recolhidos, conforme estipulado na legislação pátria, em especial na Lei Geral de Proteção de Dados.
            </p><p>8.10. O USUÁRIO declara estar ciente de que as medidas de segurança relativas à rede mundial de computadores não são integralmente infalíveis.
            </p><p>9. LEI GERAL DE PROTEÇÃO DE DADOS
            </p><p>9.1. A prestação do serviço descrito nesse contrato pela CONTRATADA obedece às leis brasileiras, em especial a Lei Geral de Proteção de Dados, nos seguintes termos:
            </p><p>a) Informações que coletamos sobre o USUÁRIO: Coletamos suas informações pessoais diretamente de você através de suas interações, uso e experiências com nosso site, produtos e serviços;
            </p><p>b) Como usamos suas informações: Usamos suas informações pessoais para anunciar e fornecer nossos produtos e serviços enquanto cliente, além de utilizar as informações para melhorar e personalizar nossos produtos e serviços, fazer recomendações relevantes e cumprir as leis aplicáveis. Também usamos suas informações para melhorar a segurança do nosso site, produtos e serviços.
            </p><p>c) Não compartilhamos suas informações pessoais.
            </p><p>d) Como usamos Cookies: Os sites, serviços online, aplicativos interativos, mensagens de e-mail e anúncios podem usar cookies e tecnologias similares para nos ajudar a adaptar sua experiência, entender melhor suas preferências, nos dizer quais partes de nossos sites você visitou, facilitar e medir a eficácia de nossos anúncios baseados em interesse e pesquisas na internet.
            </p><p>e) Acesse, exclua ou atualize suas informações e entre em contato conosco: Você pode exercer seus Direitos de Privacidade em relação às suas informações pessoais acessando o SUPORTE TÉCNICO.
            </p><p>f) O USUÁRIO poderá exercer os direitos de privacidade previstos na Lei Geral de Proteção de Dados.
            </p><p>g) Adotamos medidas de proteção técnicas, organizacionais e de segurança adequadas, inclusive de segurança física de informações, para proteger os seus dados pessoais contra uso incorreto, interferência e perda, bem como acesso não autorizado, modificação ou divulgação, conforme exigido por lei.
            </p><p>10. OUTRAS CONDIÇÕES
            </p><p>10.1. As partes concordam que a CONTRATADA poderá, a seus exclusivos critérios, alterar qualquer procedimento técnico referente aos serviços contratados sem prévio aviso.
            </p><p>10.2. O não exercício por qualquer uma das partes de qualquer direito consagrado no presente instrumento não representará novação, transação ou renúncia de tal direito, o qual poderá ser exercido a qualquer tempo.
            </p><p>10.3. Se qualquer cláusula, termo ou condição deste Contrato for considerada nula ou vier a ser anulada por qualquer decisão judicial transitada em julgado, tal nulidade ou anulabilidade não contaminará as demais cláusulas deste Contrato, o qual permanecerá em pleno vigor, desde que ainda se possa manter o equilíbrio contratual ora ajustado.
            </p><p>11. FORO
            </p><p>As partes elegem o Fórum da Comarca de Araucária, Estado do Paraná, para dirimir quaisquer controvérsias oriundas deste contrato, renunciando a qualquer outro, por mais privilegiado que seja.
            </p><p>E por estarem as partes, CONTRATADA e CONTRATANTE de pleno acordo com o disposto neste instrumento particular, assinam-no digitalmente para que produza seus efeitos legais.
            </p><p>TERMOS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE
            </p><p>Pelo presente instrumento particular de licença de uso de SISTEMA DE GESTÃO, de um lado APP LOJA, CNPJ n. 27.726.640/0001-30, com sede na Avenida Luiz Armando Ophis, 294, Estação, Município de Araucária, Estado do Paraná, doravante denominada “CONTRATADA” e ainda, de outro lado, a pessoa, física ou jurídica, doravante denominada “USUÁRIO” ou “CONTRATANTE”, têm entre si justo e avençado o seguinte:
            </p><p>1. ACEITAÇÃO DO CONTRATO E DOCUMENTOS INTEGRANTES
            </p><p>1.1. Ao optar por um dos planos oferecidos, o USUÁRIO automaticamente concederá aceitação irrevogável ao conteúdo do presente instrumento, e concordará, ainda, que qualquer indivíduo que utilizar a sua conta em seu nome também respeitará as condições aqui estipuladas.
            </p><p>1.2. A aceitação deste manifesta a vontade inequívoca das Partes no contrato celebrado por meio eletrônico e na concordância das condições necessárias para utilização do serviço,
            </p><p>1.3. não aceitação ou violação implicará no encerramento de sua conta.
            </p><p>1.4. A CONTRATADA se resguarda no direito, de revisar estas condições a qualquer momento, sendo que o uso continuado do serviço por parte do USUÁRIO será considerado como aceitação tácita de tais revisões, estando desde já ciente que versão mais atual poderá ser consultada a qualquer momento no site.
            </p><p>2. DEFINIÇÕES
            </p><p>2.1. Fica estabelecido as seguintes definições:
            </p><p>a) USUÁRIO/USUÁRIOS: é a pessoa física ou jurídica que contrata o serviço.
            </p><p>b) SUPORTE TÉCNICO: é o serviço de suporte técnico disponibilizado ao USUÁRIO pelo telefone (41) 9 9531-3382 ou e-mail <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="15767a7b6174617a55746565797a7f743b767a783b">[email&#160;protected]</a>
            </p><p>c) SISTEMA DE GESTÃO: é a disponibilização, pela CONTRATADA, de um conjunto de ferramentas e serviços online destinados aos USUÁRIOS. Os Serviços são fornecidos mediante acesso à internet, por meio de dispositivos próprios de propriedade do USUÁRIO. Para tanto, a CONTRATADA se exime de qualquer responsabilidade sobre os equipamentos e meios de acesso utilizados pelos USUÁRIOS, como, por exemplo, computador, provedores de acesso à Internet, modem, bem como, qualquer perda de dados.
            </p><p>d) SITE: é a página ou sequência de páginas do SISTEMA DE GESTÃO desenvolvido pela CONTRATADA, com a finalidade de ofertar o Serviço.
            </p><p>3. OBJETO
            </p><p>3.1. Pelo presente contrato compromete-se a CONTRATADA a fornecer à CONTRATANTE, a licença de uso do(s) sistema(s) abaixo descrito(s), conforme plano contratado, cujas especificidades estão descritas na página principal do SITE:
            </p><p>a) PRO:
            </p><p>Preço: R$ 57,50 (Pagamento único)
            </p><p>Licença Vitalícia
            </p><p>Sistema apenas para PC
            </p><p>Sistema para computador
            </p><p>Cadastro de Produtos
            </p><p>Cadastro Fornecedores
            </p><p>Cadastro Clientes
            </p><p>Ponto de Venda PDV
            </p><p>Controle de Pedidos
            </p><p>Controle de Usuários
            </p><p>Controle de Vendedores
            </p><p>Controle de Compras
            </p><p>Emissão de Carnê
            </p><p>Nota Promissória
            </p><p>Controle de Consignado
            </p><p>Emissão de Boleto
            </p><p>Controle de Despesas
            </p><p>Controle de Receitas
            </p><p>Controle de Caixa
            </p><p>Extrato Financeiro
            </p><p>Controle de Entregas
            </p><p>Ordem de Serviço
            </p><p>Suporte para Instalação
            </p><p>b) Premium:
            </p><p>Preço: R$ 142,50 (Pagamento anual)
            </p><p>Sistema para PC integrado a aplicativo android para vendas externas em até 3 celulares. Sendo cobrado R$ 50 por celular adicional
            </p><p>No PC tem os seguintes recursos
            </p><p>Cadastro de Produtos
            </p><p>Cadastro Fornecedores
            </p><p>Cadastro Clientes
            </p><p>Venda Balcão PDV
            </p><p>Controle de Pedidos
            </p><p>Controle de acesso por usuário
            </p><p>Compra de Materiais
            </p><p>Emissão de Carnê
            </p><p>Nota Promissória
            </p><p>Emissão de Boleto
            </p><p>Controle de Despesas
            </p><p>Controle de Receitas
            </p><p>Controle de Caixa
            </p><p>Margem de lucro
            </p><p>Controle de Entregas
            </p><p>Ordem de Serviço
            </p><p>Suporte para Instalação
            </p><p>Recursos do aplicativo android
            </p><p>Vendas
            </p><p>Cadastro e consulta de produtos
            </p><p>Cadastro e consulta de clientes e fornecedores
            </p><p>Abertura e fechamento do caixa
            </p><p>Sincronização das vendas, efetuadas no celular, para o PC
            </p><p>c) Completo:
            </p><p>Preço: À partir de 89,90 mensal
            </p><p>Sistema web com emissão de nota fiscal
            </p><p>Acesse pelo no navegador em qualquer lugar
            </p><p>Agenda
            </p><p>Aplicativo Android e IOS
            </p><p>Venda pelo celular
            </p><p>Emissor de Notas
            </p><p>NF-e / NFS-e / NFC-e / CT-e / MDF-e
            </p><p>Controle de Vendas
            </p><p>PDV
            </p><p>Pedidos
            </p><p>Ordem de Serviço
            </p><p>Loja Virtual Integrada
            </p><p>Controle Financeiro
            </p><p>Contas à Pagar
            </p><p>Contas à Receber
            </p><p>Boleto
            </p><p>Integração com Ecommerce
            </p><p>Marketplace
            </p><p>Antecipação de recebíveis em Boleto
            </p><p>Controle de Estoque
            </p><p>Relatórios Detalhados
            </p><p>Avisos Internos
            </p><p>Recursos Adicionais
            </p><p>d) Delivery:
            </p><p>Sistema Desktop
            </p><p>Mobile (Android)
            </p><p>Delivery
            </p><p>3.2. O suporte técnico refere-se à correção de defeitos e dúvidas que não possam ser sanadas pelos tutoriais limitado, sendo limitado à 10 (dez) atendimentos ou 3 (três) meses da compra.
            </p><p>3.2.1. Após o término dos 10 (dez) atendimentos, ou do período de 3 (três) meses, será cobrado R$ 5 (cinco reais) por atendimento.
            </p><p>3.2.2. O suporte técnico será prestado de segunda-feira à sexta-feira, no horário de 9 (nove) às 18 (dezoito) horas.
            </p><p>3.2.3. O prazo de atendimento é de até 3 horas úteis, podendo ser maior em razão da complexidade da situação e da demanda.
            </p><p>3.3. Poderá ser contratado treinamento para o conhecimento das funcionalidades do sistema no valor de R$ 25 (vinte e cinco reais) por hora.
            </p><p>3.4. O serviço será prestado da seguinte forma:
            </p><p>a) acesso pago com diversas opções de pacotes de funcionalidades, cujas especificidades estão descritas na página principal do SITE;
            </p><p>b) após a confirmação do pagamento, será gerada a chave de ativação para download e enviadas instruções para utilização do sistema;
            </p><p>c) a versão gratuita do SISTEMA DE GESTÃO por ser utilização sem limitação de tempo;
            </p><p>d) é possível o cancelamento da contratação no prazo máximo de 7 (sete) dias da escolha do plano;
            </p><p>e) é possível o upgrade de forma imediata em relação ao plano contratado, mediante a contratação de um novo plano, com a utilização do crédito remanescente do plano anterior para extensão do período de uso no novo plano;
            </p><p>f) é possível o downgrade de forma imediata em relação ao plano escolhido. Porém, não haverá devolução do valor pago a maior.
            </p><p>3.5. A CONTRATADA se reserva o direito de mudar, modificar, suspender ou descontinuar qualquer plano, cujas especificidades estão descritas na página principal do SITE, bem como as opções de contas, valores e formatos a serem ofertados ao USUÁRIO.
            </p><p>3.6. O presente contrato terá a seguinte duração:
            </p><p>a) PRO: Licença Vitalícia;
            </p><p>b) Premium: 12 (doze) meses contados a partir da formalização da opção pelo plano;
            </p><p>c) Completo: 01 (um) mês;
            </p><p>d) Delivery: 01 (um) mês;
            </p><p>3.7. Após o período de duração mencionado no item anterior, o SISTEMA DE GESTÃO apresentará um alerta de expiração com prazo de 7 (sete) dias para a CONTRATANTE renovar sua opção pelo plano escolhido ou optar por outro plano. Caso a opção não seja feita, o uso do SISTEMA DE GESTÃO será bloqueado.
            </p><p>3.8. O USUÁRIO é o único responsável pelo cancelamento do serviço, o qual só poderá ser feito respeitando as condições previstas no presente contrato.
            </p><p>3.9. Após o cancelamento do serviço, todas as informações, dados e arquivos ficarão disponíveis para backup pelo prazo de 90 (noventa) dias, após o qual, serão automaticamente apagados.
            </p><p>3.10. Caso o cancelamento seja feito em desrespeito às condições estipuladas neste instrumento, o encerramento da conta terá efeito imediato e o USUÁRIO não terá direito ao reembolso do período contratado.
            </p><p>3.11. A CONTRATADA se resguarda no direito de rescindir imediatamente o contrato de um determinado USUÁRIO caso haja indícios de que o SISTEMA DE GESTÃO esteja utilizando de forma ilícita ou vinculado a atos ilícitos.
            </p><p>4. PREÇO
            </p><p>4.1. O USUÁRIO deverá pagar à CONTRATADA o valor do plano contratado, cujas especificidades e valores estão descritas na página principal do SITE no ato da contratação.
            </p><p>4.2. A data de vencimento contar-se-á a partir da data de contratação, conforme as especificidades do plano contratado, descritas na página principal do SITE no ato da contratação.
            </p><p>4.3. Na hipótese da duração do contrato ser anual, os valores ora pactuados serão corrigidos, de forma anual, pelo IGPM ou outro indexador oficial que venha a substituí-lo. A correção dar-se-á periodicamente a cada 12 (doze) meses. Em caso de variação negativa do índice de correção, não haverá redução do valor anteriormente estabelecido.
            </p><p>4.4. A falta de pagamento dos valores acordados nas respectivas datas de vencimento não acarretará a rescisão automática do contrato, mas implicará na suspensão do acesso do USUÁRIO ao SISTEMA DE GESTÃO até que as pendências financeiras tenham sido regularizadas. O acesso ao SISTEMA DE GESTÃO somente será restabelecido após a identificação do pagamento integral de todos os valores devidos pelo USUÁRIO, mesmo durante o período em que o acesso esteve suspenso.
            </p><p>4.5. A compensação do pagamento poderá ocorrer em até 2 (dois dias úteis) após a data de pagamento.
            </p><p>4.6. Caso a pendência no pagamento dos valores contratados permaneça por prazo superior à 90 (noventa) dias contados da data de vencimento do valor não pago, a CONTRATADA se reserva no direito de rescindir o presente contrato. Nesse caso, o USUÁRIO declara estar ciente que todos os seus dados e informações inseridos no SISTEMA DE GESTÃO serão apagados de forma definitiva e irrecuperável.
            </p><p>5. OBRIGAÇÕES DA CONTRATADA
            </p><p>5.1. São obrigações da CONTRATADA, além das demais previstas neste instrumento e na legislação aplicável:
            </p><p>a) Disponibilizar o SISTEMA DE GESTÃO envidando os melhores esforços para assegurar e desenvolver a qualidade do mesmo, comprometendo-se, ainda, a respeitar a privacidade do USUÁRIO, garantindo que não monitorará ou divulgará informações relativas a sua utilização, mantendo sigilo sobre as informações cadastrais fornecidas, inclusive login e senha, que só serão divulgados a terceiros em razão de determinação judicial, ressalvadas as hipóteses previstas neste Contrato, respeitando-se a legislação brasileira vigente sobre o tema;
            </p><p>b) Comunicar ao USUÁRIO por e-mail ou aviso veiculado no Portal e/ou no SITE a interrupção da prestação do serviço por ocasião de manutenções preventivas e/ou corretivas em sua rede;
            </p><p>c) Manter, por si ou por terceiros, o SUPORTE TÉCNICO;
            </p><p>d) Prover, nos termos deste contrato, os suportes técnicos que forem indispensáveis ao uso do SISTEMA DE GESTÃO.
            </p><p>6. OBRIGAÇÕES DO USUÁRIO
            </p><p>6.1. São obrigações do USUÁRIO, além das demais previstas neste instrumento e na legislação aplicável:
            </p><p>a) Pagar os valores devidos de acordo com o plano escolhido;
            </p><p>b) Responsabilizar-se pelo uso e guarda de seu login e senha de acesso ao SISTEMA DE GESTÃO;
            </p><p>c) Utilizar o SISTEMA DE GESTÃO colocados à sua disposição somente para os fins estabelecidos no presente instrumento, honrando os compromissos legais aqui assumidos;
            </p><p>d) Utilizar o SISTEMA DE GESTÃO para fins legais ou em consonância com a legislação brasileira.
            </p><p>6.2. O USUÁRIO será o único e exclusivo responsável pela utilização do SISTEMA DE GESTÃO, respondendo perante a CONTRATADA e/ou terceiros pelos danos e prejuízos, inclusive lucros cessantes que vier a ocasionar em razão da má utilização do mesmo.
            </p><p>6.3. O USUÁRIO será o único responsável pelas infrações que cometer quanto ao direito de uso dos softwares ou outro processo de execução protegido por marcas e patentes relacionados a este instrumento, respondendo diretamente por quaisquer indenizações, taxas ou comissões que forem devidas, bem como por quaisquer reclamações resultantes de sua utilização inadequada.
            </p><p>6.4. São de propriedade exclusiva da CONTRATADA o conteúdo do SITE, como textos, gráficos, planilhas, ícones, desenhos, programas de computador, aí incluídos o suporte lógico, a descrição básica e o material de apoio, os dados de qualquer natureza relacionados aos serviços objeto deste contrato, estando todos protegidos pelas leis brasileiras de direito intelectual, especialmente as de direito autoral, de proteção jurídica do programa de computador, de marcas, patentes, modelos e desenhos industriais, bem como pelos tratados internacionais relativos a matéria.
            </p><p>6.5. O uso não autorizado, a divulgação, ainda que restrita, especialmente a de caráter comercial, a publicação, distribuição, transmissão, compilação, alteração, assim como a reprodução total ou parcial não autorizada dos conteúdos disponibilizados pelo SITE, por meio mecânico, eletrônico ou qualquer outro, são práticas estritamente proibidas, sujeitando o infrator as sanções de natureza penal, civil e administrativa cabíveis.
            </p><p>7. INTERRUPÇÕES, GARANTIAS E RESPONSABILIDADES
            </p><p>7.1. O uso do SISTEMA DE GESTÃO poderá ser interrompido, sem que haja qualquer direito de indenização ou compensação ao USUÁRIO, eventualmente para:
            </p><p>a) manutenções técnicas e/ou operacionais que exijam o desligamento temporário do sistema ou impossibilitem o acesso ao mesmo;
            </p><p>b) casos fortuitos ou de força maior;
            </p><p>c) ações de terceiros que impeçam a prestação contínua do serviço;
            </p><p>d) falta de fornecimento de energia elétrica por parte da concessionária pública;
            </p><p>e) interrupção ou suspensão da prestação dos serviços de telecomunicações;
            </p><p>f) ocorrências de falhas no sistema de transmissão e/ou roteamento no acesso à Internet.
            </p><p>7.2. A CONTRATADA não será responsável por danos e/ou prejuízos decorrentes de interrupções relacionadas aos eventos previstos nos itens acima, ou de outros em que não tenha concorrido exclusivamente para sua ocorrência.
            </p><p>7.3. Em razão de situações de fragilidade ou eventualidades, que podem ocasionar a suspensão ou falha temporária de algumas funcionalidades, a CONTRATADA não garante o funcionamento do SISTEMA DE GESTÃO de forma ininterrupta e/ou isenta de erros, mas se compromete sempre a zelar pelo restabelecimento das condições ideais de uso.
            </p><p>7.4. A CONTRATADA isenta-se expressamente de quaisquer responsabilidades e indenizações, perdas e danos, lucros cessantes, prejuízos de quaisquer espécies ou sob quaisquer títulos, perdas de negócios, perda ou extravio de dados, defeitos de computador, equipamentos ou periféricos, ou quaisquer outros danos diretos, indiretos, acidentais, especiais, consequenciais ou punitivos, decorrentes direta ou indireta da utilização do SISTEMA DE GESTÃO causados ao USUÁRIO ou a terceiros.
            </p><p>7.5. A CONTRATADA assegura ao CONTRATANTE a possibilidade de backup de seus dados mesmo com o uso do plano suspenso, assegurando ainda em casos de cancelamento do serviço, o prazo de 90 (noventa) dias para backup das informações e dados armazenados no SISTEMA DE GESTÃO.
            </p><p>8. DADOS CADASTRAIS
            </p><p>8.1. Ao efetuar seu cadastro aceitando as condições e termos deste contrato, o USUÁRIO declara e garante, expressamente, para todos os fins de direito, possuir capacidade jurídica para sua celebração, bem como que é financeiramente responsável pela utilização dos produtos e serviços objeto deste Contrato.
            </p><p>8.2. O USUÁRIO compromete-se a comunicar imediatamente a CONTRATADA sobre qualquer extravio, roubo, perda ou uso não autorizado de seu login e/ou senha, a fim de que possam ser bloqueados ou modificados.
            </p><p>8.3. A CONTRATADA exonera-se de toda e qualquer responsabilidade decorrente do uso indevido, negligente ou imprudente do login e/ou da senha por parte do USUÁRIO.
            </p><p>8.4. O USUÁRIO deverá fornecer, no momento da contratação, informações verdadeiras, atualizadas e completas, sendo facultado à CONTRATADA utilizar serviço próprio ou de terceiros para apurar a validade e veracidade das informações prestadas. O USUÁRIO deverá, ainda, informar qualquer alteração em seus dados cadastrais, inclusive mudança de endereços convencional ou e-mail.
            </p><p>8.5. Caso os dados informados pelo USUÁRIO no momento do cadastramento estejam errados ou incompletos, sofram alteração não comunicada nos termos do item anterior, ou impossibilitem a comprovação e identificação do USUÁRIO, a CONTRATADA poderá, a seus exclusivos critérios, suspender ou cancelar automaticamente e independente de comunicação prévia, os serviços ora contratados.
            </p><p>8.6. O registro e a utilização eletrônica dos dados cadastrais, tem como finalidade o estabelecimento do vínculo contratual, a gestão, administração, prestação, ampliação e aprimoramento dos serviços ao USUÁRIO, bem como a adequação dos serviços às preferências e gostos do USUÁRIO, a criação de novos serviços, o envio de atualizações dos serviços, o envio, por meios tradicionais e/ou eletrônicos, de informações técnicas, operacionais e comerciais relativas a produtos e serviços oferecidos direta ou indiretamente pela CONTRATADA.
            </p><p>8.7. A finalidade do registro e do tratamento eletrônico dos dados cadastrais inclui, igualmente, o envio de formulários de pesquisas, aos quais o USUÁRIO não fica obrigado a responder.
            </p><p>8.8. A CONTRATADA deverá obter o prévio e inequívoco consentimento do USUÁRIO para utilizar os dados cadastrais com propósitos diversos ao estabelecido nesta Cláusula.
            </p><p>8.9. A CONTRATADA garante que adota os melhores níveis quanto à segurança na proteção dos referidos dados cadastrais do USUÁRIO, tendo instalado os meios e medidas técnicas para tentar evitar a perda, mau uso, alteração, acesso não autorizado ou subtração indevida dos dados cadastrais recolhidos, conforme estipulado na legislação pátria, em especial na Lei Geral de Proteção de Dados.
            </p><p>8.10. O USUÁRIO declara estar ciente de que as medidas de segurança relativas à rede mundial de computadores não são integralmente infalíveis.
            </p><p>9. LEI GERAL DE PROTEÇÃO DE DADOS
            </p><p>9.1. A prestação do serviço descrito nesse contrato pela CONTRATADA obedece às leis brasileiras, em especial a Lei Geral de Proteção de Dados, nos seguintes termos:
            </p><p>a) Informações que coletamos sobre o USUÁRIO: Coletamos suas informações pessoais diretamente de você através de suas interações, uso e experiências com nosso site, produtos e serviços;
            </p><p>b) Como usamos suas informações: Usamos suas informações pessoais para anunciar e fornecer nossos produtos e serviços enquanto cliente, além de utilizar as informações para melhorar e personalizar nossos produtos e serviços, fazer recomendações relevantes e cumprir as leis aplicáveis. Também usamos suas informações para melhorar a segurança do nosso site, produtos e serviços.
            </p><p>c) Não compartilhamos suas informações pessoais.
            </p><p>d) Como usamos Cookies: Os sites, serviços online, aplicativos interativos, mensagens de e-mail e anúncios podem usar cookies e tecnologias similares para nos ajudar a adaptar sua experiência, entender melhor suas preferências, nos dizer quais partes de nossos sites você visitou, facilitar e medir a eficácia de nossos anúncios baseados em interesse e pesquisas na internet.
            </p><p>e) Acesse, exclua ou atualize suas informações e entre em contato conosco: Você pode exercer seus Direitos de Privacidade em relação às suas informações pessoais acessando o SUPORTE TÉCNICO.
            </p><p>f) O USUÁRIO poderá exercer os direitos de privacidade previstos na Lei Geral de Proteção de Dados.
            </p><p>g) Adotamos medidas de proteção técnicas, organizacionais e de segurança adequadas, inclusive de segurança física de informações, para proteger os seus dados pessoais contra uso incorreto, interferência e perda, bem como acesso não autorizado, modificação ou divulgação, conforme exigido por lei.
            </p><p>10. OUTRAS CONDIÇÕES
            </p><p>10.1. As partes concordam que a CONTRATADA poderá, a seus exclusivos critérios, alterar qualquer procedimento técnico referente aos serviços contratados sem prévio aviso.
            </p><p>10.2. O não exercício por qualquer uma das partes de qualquer direito consagrado no presente instrumento não representará novação, transação ou renúncia de tal direito, o qual poderá ser exercido a qualquer tempo.
            </p><p>10.3. Se qualquer cláusula, termo ou condição deste Contrato for considerada nula ou vier a ser anulada por qualquer decisão judicial transitada em julgado, tal nulidade ou anulabilidade não contaminará as demais cláusulas deste Contrato, o qual permanecerá em pleno vigor, desde que ainda se possa manter o equilíbrio contratual ora ajustado.
            </p><p>11. FORO
            </p><p>As partes elegem o Fórum da Comarca de Araucária, Estado do Paraná, para dirimir quaisquer controvérsias oriundas deste contrato, renunciando a qualquer outro, por mais privilegiado que seja.
            </p><p>E por estarem as partes, CONTRATADA e CONTRATANTE de pleno acordo com o disposto neste instrumento particular, assinam-no digitalmente para que produza seus efeitos legais.
            </p><p></p><p></p>
          </div>
    )
}